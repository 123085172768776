/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useContext, useEffect, useState, useCallback } from "react";
import toast from "react-hot-toast";
import { authenticate, unauthenticate, currentUser, mutate, CurrentUserObject } from "@onflow/fcl";
import { useQuery } from "@tanstack/react-query";
import { captureMessage } from "@sentry/react";
import { getDapperSetupEndpoint } from "apis/walkthrough";
import { PURCHASE_TRANSACTION, USER_ACCOUNT_SETUP_TXN } from "DapperTxn";
import { useSearchParams } from "react-router-dom";
import { skipUserOnboardingForBrandList } from "constants/constants";
import firebasedb from "utils/firebase";
import { onValue, ref as firebaseRef } from "firebase/database";
import useNotificationStore from "globalstore/notificationStore";
import { useRouter } from "components/hooks";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useStripe } from "@stripe/react-stripe-js";
import * as fcl from "@onflow/fcl";
import { DAPPER_WALLET_PAGE, LOCAL_STORAGE_KEYS, ROUTES } from "../../constants";
import { get, post } from "../../utils/request";
import Loading from "../atoms/Loading";
import useLocalStorage from "../hooks/useLocalStorage";
// @ts-ignore

import initFCL from "../../flow-config";
import { getLogoutURL, getSettlePurchaseURL, getUserSelfURL } from "../../apis/user";
import { LoaderContext } from "./LoaderContext";

type GetArgsResponseType = {
  merchant_wallet: string;
  partner_wallet: string;
  listing_resource_id: number;
  price: number;
};

export type UserContextType = {
  token: string | undefined;
  setTokenId: (token: string) => void;
  loading: boolean;
  userDetails: undefined | User | null;
  reloadUserData: () => void;
  fclUser: any;
  handleUserLogin: any;
  handleFCLLogout: any;
  handleUserLogout: any;
  isUserLoggedIn: boolean;
  setupUserAccountAndAirDropMagnet: () => Promise<void>;
  handleBuyWithDapper: (magnetId: string) => Promise<string | null>;
  handleBuyWithStripe: (
    magnetId: string,
    beforePurchaseCallback?: (response: any) => Promise<boolean>,
  ) => Promise<string | null>;
  handleRedirectToDapper: (walletAddress: string, walletType: string) => void;
  isMagnetInUserInventory: (magnetId: string) => boolean;
  currentlyViewingMementoId: string | null;
  setCurrentlyViewingMementoId: (id: string | null) => void;
  notificationSubType: string | null;
  setNotificationSubType: (subType: string | null) => void;
};

const notInitialised = (): void => {
  toast.error("Ctx not init");
};

export const UserContext = React.createContext<UserContextType>({
  token: undefined,
  loading: true,
  userDetails: undefined,
  reloadUserData: notInitialised,
  fclUser: undefined,
  handleUserLogin: notInitialised,
  handleFCLLogout: notInitialised,
  handleUserLogout: notInitialised,
  isUserLoggedIn: false,
  // @ts-ignore
  setupUserAccountAndAirDropMagnet: notInitialised,
  // @ts-ignore
  handleBuyWithDapper: notInitialised,
  // @ts-ignore
  handleBuyWithStripe: notInitialised,
  setTokenId: notInitialised,
  handleRedirectToDapper: notInitialised,
  currentlyViewingMementoId: null,
  setCurrentlyViewingMementoId: notInitialised,
  notificationSubType: null,
  setNotificationSubType: notInitialised,
});

export default function UserProvider({ children }: any): JSX.Element {
  const { setLoading: setGlobalLoader } = useContext(LoaderContext);
  const stripe = useStripe();
  const [token, setTokenId] = useLocalStorage<string>(LOCAL_STORAGE_KEYS.USER_TOKEN);
  // @ts-ignore
  const [fclUser, setFclUser] = useState<CurrentUserObject>({ loggedIn: null });
  const router = useRouter();

  const { ref: referred } = router.query;

  const [searchParams, setSearchParams] = useSearchParams();

  const redirectURI = searchParams.get("redirectURI");

  const [, setSkipOnboarding] = useLocalStorage(LOCAL_STORAGE_KEYS.SKIP_ONBOARDING);

  const [currentlyViewingMementoId, setCurrentlyViewingMementoId] = useState<string | null>(null);

  const [notificationSubType, setNotificationSubType] = useState<string | null>(null);

  const { setNotifications } = useNotificationStore();

  useEffect(() => {
    // This will be executed whenever the user
    currentUser.subscribe(handleSetFCLUserAndGetAuthToken);
  }, []);

  useEffect(() => {
    if (skipUserOnboardingForBrandList.includes(referred)) {
      setSkipOnboarding(true);

      const { ref, ...rest } = router.query;
      // Clear the ref param from the URL while retaining other search params
      setSearchParams(rest);
    }
  }, [router.query]);

  async function handleSetFCLUserAndGetAuthToken(user: CurrentUserObject) {
    try {
      setGlobalLoader(true);
      setFclUser(user);
      // if user lands on the application, doesnt have token in local storage.
      if (!token && user.loggedIn === null && user.addr === null) {
        setGlobalLoader(false);
      }
      if (user.loggedIn === fclUser.loggedIn && user?.addr === fclUser?.addr) {
        // console.log("User Duplicate Run.", user, fclUser);ssssss
      }
      if (user.loggedIn) {
        // console.log("TOKEN", token);
        if (!token) {
          // console.log("NO TOKEN FOUND REQUESTING FROM BE..");
          await initFCL();
          // await verifySignatureAndGetTokenFromBE(user);
        } else {
          // There is a token in the BE nothing needs to be done here.
          // console.log("TOKEN FROM BE FOUND.");
        }
      } else {
        // On logout we might need to do some additional steps here.
        // console.error("USER LOGOUT");
      }
    } catch {
      setGlobalLoader(false);
    }
  }

  const {
    data: userDetailsData,
    isLoading,
    isError,
    refetch: reloadUserData,
    isFetching,
  } = useQuery({
    // Enabled when user is logged in via fcl and token is present from BE.
    enabled: !!token,
    // This gets triggered when there is an active token from BE and, and valid wallet address is received from User
    queryFn: () =>
      // Allowed Redirect from Only 2 routes for now. as adding other routes causes it to redirect to USER_PROFILE PAGE ROUTE.
      getAndSetUserWithRedirect(token, [ROUTES.SIGNIN].includes(window.location.pathname)),
    queryKey: ["userDetails", token, fclUser?.addr],
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (userDetailsData?.email) {
      const notificationForUser = firebaseRef(firebasedb, userDetailsData.firebase_path);

      return onValue(notificationForUser, (snapshot) => {
        const data = snapshot.val();

        if (data === null) {
          setNotifications([]);
        }
        if (snapshot.exists()) {
          setNotifications(Object.values(data || []).reverse());
        }
      });
    }
  }, [userDetailsData]);

  const isUserDetailsLoading = isLoading && isFetching;

  // // Automatically login if token is present in local storage.
  // useEffect(() => {
  //   // TODO: fix this
  //   const magnetClaimPageURL = "/magnet/[A-Za-z0-9]+/claim/[A-Za-z0-9_]+"; // login to not trigger for this route
  //   if (router.pathname.match(magnetClaimPageURL)) return;
  //   if (token) {
  //     setGlobalLoader(true);
  //     handleFCLLogin();
  //   }
  // }, [token]);

  // async function verifySignatureAndGetTokenFromBE(user: any) {
  //   try {
  //     setGlobalLoader(true);

  //     const response = await post<any>(getVerifySignatureURL(), {
  //       accountProofServiceData: user.services.find((services: any) => services.type === "account-proof")?.data,
  //       openIDData: user.services.find((services: any) => services.type === "open-id")?.data,
  //     });

  //     if (!response || !response?.verified) {
  //       toast.error("Failed to verify signature from BE");
  //       return null;
  //     }

  //     // setTokenId(response.token);
  //     // setupUserAccount();
  //     // router.go(ROUTES.USER_PROFILE_PAGE);
  //     return response;
  //   } catch (err) {
  //     toast.error("error logging in with FCL");
  //     setGlobalLoader(false);
  //     return null;
  //   }
  // }

  async function handleFCLLogin() {
    try {
      setGlobalLoader(true);
      await initFCL();
      // Once authenticated this will trigger a state update for fclUser.
      authenticate();
    } catch (err) {
      toast.error("Failed to authenticate with FCL.");
      setGlobalLoader(false);
    }
  }

  const handleFCLLogout = () => {
    unauthenticate();
  };

  const handleUserLogout = () => {
    handleFCLLogout();
    post(
      getLogoutURL,
      {},
      {
        processError: () => {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_TOKEN);
          // localStorage.clear();
          // router.go(ROUTES.SIGNIN);
          window.location.reload();
        },
        processData: () => {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_TOKEN);
          // localStorage.clear();
          // router.go(ROUTES.SIGNIN);
          window.location.reload();
        },
      },
    );
  };

  // This is called multiple times when the user is logged in via FCL and token is present from BE.
  const getAndSetUserWithRedirect = async (userToken: string, redirect?: boolean): Promise<User | null> => {
    // Special Case: When the user query is reloaded via the reload fn irrespective of the enabled condition, this will be called.
    // Currently Reload User Data is trigged whenever user clicks on a notification, magnet activity.
    if (!userToken) {
      captureMessage("Get and Set User With Redirect Called with NO token.", {});
      return null;
    }

    const userData: User = await get(getUserSelfURL(), {
      processError: (errorMessage, error, errorData) => {
        // User is unauthorized or token could have expired.
        // Logging out the user for now.
        if (errorData?.statusCode === 401) {
          // console.log("Unauthorised....");
          handleUserLogout();
        }
        toast.error(errorMessage);
        throw new Error(errorMessage);
      },
    });

    setGlobalLoader(false);

    // For the user to be marked as 1 on the onboard status, backend takes a finite amount of time during this phase
    // if (userData?.magnetiq_program_status === 0 && window.location.pathname !== ROUTES.CREATE_USER_ACCOUNT) {
    //   // console.log("Redirecting to create account page. with", redirect, redirectURI);
    //   // Redirect to Create Account Page
    //   router.go(ROUTES.CREATE_USER_ACCOUNT + (redirect && redirectURI ? `?redirectURI=${redirectURI}` : ""), {
    //     replace: true,
    //   });
    //   return userData;
    // }

    if (redirect)
      router.go(redirectURI || ROUTES.BRANDS_DISCOVER, {
        replace: true,
      });
    return userData;
  };

  const getTransactionArgs = async (magnetId: string) => {
    await initFCL(false);
    const txnArgs = await post<GetArgsResponseType>(
      `/api/v1/magnet/${magnetId}/getPurchaseArgs/`,
      {},
      {
        processError: (errorMessage) => toast.error(errorMessage),
      },
    );

    if (txnArgs) {
      return txnArgs;
    }
    throw new Error("GET TRANSACTION ARGS FAILED.");
  };

  const getStripeSession = async (magnetId: string) => {
    const stripeSession = await get(
      `/api/v1/payments/create-checkout-session/magnet/${magnetId}?redirect_url=${encodeURIComponent(
        window.location.href,
      )}`,
      {
        processError: (errorMessage: any) => toast.error(errorMessage),
      },
    );

    if (stripeSession) {
      return stripeSession;
    }
    throw new Error("GET TRANSACTION ARGS FAILED.");
  };

  const handleSetupSuccess = () => {
    toast.success("Wallet linked successfully");
    // Trigger a reload of user data after account creation.
    reloadUserData();
  };

  const handleAirDropMagnetError = (errorMessage: string) => {
    toast.error(errorMessage);
  };

  const setupDapper = async (txnData: string, userAddress: string, walletType: string) => {
    post(
      getDapperSetupEndpoint,
      { txn_data: txnData, user_address: userAddress, wallet_type: walletType },
      {
        processData: handleSetupSuccess,
        processError: handleAirDropMagnetError,
      },
    );
  };

  const setupUserAccountAndAirDropMagnet = async () => {
    try {
      await initFCL(true);
      await fcl.authenticate();
      // Get the current user's wallet address
      const userSnapshot = await fcl.currentUser.snapshot();
      const userAddress = userSnapshot.addr;

      if (!userAddress) {
        throw new Error("User is not authenticated or wallet address is unavailable");
      }

      // @ts-ignore
      const walletType = userSnapshot.services[0]?.provider.name;
      console.log("Wallet type:", walletType);
      console.log("User Wallet Address:", userAddress);
      const txId = await fcl.mutate({
        cadence: USER_ACCOUNT_SETUP_TXN,
      });

      if (!txId) {
        throw new Error("No transaction ID present");
      }

      console.log("Transaction ID:", txId);
      // const txStatus = await fcl.tx(txId).onceSealed();
      // console.log("Transaction Status:", txStatus);
      // // Display emitted events
      // txStatus.events.forEach((event) => {
      //   if (event.type.includes("LogMessage")) {
      //     console.log("Transaction Event Log:", event.data.message);
      //   }
      // });

      await setupDapper(txId, userAddress, walletType);
      reloadUserData();
    } catch (error) {
      console.error("Error Script Test Result:", error);
      toast.error("Something went wrong please try again.");
    }
  };

  const handleBuyWithDapper = async (magnetId: string) => {
    try {
      if (!magnetId) return null;

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const response = await getTransactionArgs(magnetId);

      if (!response) {
        toast.error("Failed to purchase Magnet");
        return null;
      }

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { merchant_wallet, partner_wallet, listing_resource_id, price } = response;

      const txId = await mutate({
        cadence: PURCHASE_TRANSACTION,
        limit: 9999,
        args: (arg, t) => [
          arg(merchant_wallet, t.Address),
          arg(partner_wallet, t.Address),
          arg(listing_resource_id, t.UInt64),
          arg(price, t.UFix64),
        ],
      });

      if (!txId) {
        toast.error("Failed to purchase Magnet");
        return null;
      }

      const verifyTxn = await post(getSettlePurchaseURL(magnetId), { tx_hash: txId });

      if (verifyTxn === null) {
        toast.error("Failed to verify txn");
      } else {
        toast.success(
          "Your order has been received. You will receive a notification once the Magnet is added to your inventory.",
        );
      }

      return txId;
    } finally {
      setGlobalLoader(false);
    }
  };

  const handleBuyWithStripe = async (
    magnetId: string,
    beforePurchaseCallback?: (response: any) => Promise<boolean>,
  ) => {
    try {
      if (!magnetId) return null;

      setGlobalLoader(true, "Fetching payment details...");

      const response = await getStripeSession(magnetId);

      setGlobalLoader(false);

      if (!response) {
        toast.error("Failed to purchase Magnet");
        return null;
      }

      if (beforePurchaseCallback) {
        const isSuccess = await beforePurchaseCallback(response);
        if (isSuccess === false) {
          toast.error("Magnet purchase cancelled");
          return null;
        }
      }

      setGlobalLoader(true, "Taking you to stripe...");
      stripe?.redirectToCheckout({ sessionId: response?.sessionId });
      return response?.sessionId;
    } finally {
      setGlobalLoader(false);
    }
  };

  const isMagnetInUserInventory = useCallback(
    (magnetId: string) => !!userDetailsData?.inventory.find((inv) => inv.object_id === magnetId),
    [userDetailsData?.inventory],
  );

  const handleDapperRedirect = async (walletAddress: string, walletType: string) => {
    if (walletType.toLowerCase() === "dapper wallet") {
      window.open(DAPPER_WALLET_PAGE, "_blank");
    } else {
      toast("Please check your Fridge to view all attached NFTs", {
        duration: 5000,
      });
    }
  };

  // isUserDetailsFetching is needed as the isLoading will be set to true in case query is disabled.
  // Reference to the github issue. https://github.com/TanStack/query/pull/4244
  // https://github.com/TanStack/query/issues/3584
  if (isUserDetailsLoading) return <Loading />;
  if (isError) return <p>Error Fetching User Details</p>;

  return (
    <UserContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        token,
        setTokenId,
        userDetails: userDetailsData,
        loading: isUserDetailsLoading,
        reloadUserData,
        fclUser,
        handleUserLogin: handleFCLLogin,
        handleFCLLogout,
        handleUserLogout,
        handleBuyWithDapper,
        handleBuyWithStripe,
        setupUserAccountAndAirDropMagnet,
        isUserLoggedIn: Boolean(token),
        handleRedirectToDapper: handleDapperRedirect,
        isMagnetInUserInventory,
        currentlyViewingMementoId,
        setCurrentlyViewingMementoId,
        notificationSubType,
        setNotificationSubType,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUserContext = () => {
  const context = useContext(UserContext);
  return context;
};

import { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// @ts-ignore
import * as Sentry from "@sentry/react";

import GlobalMementoModalProvider from "components/GlobalMementoModalProvider";
import AuthenticatedRoute from "components/auth/AuthenticatedRoute";
import BrandRoute from "components/auth/BrandRoute";
import UserAccountRoute from "components/auth/UserAccountRoute";
import BrandProvider from "components/context/BrandContext";
import LoaderProvider from "components/context/LoaderContext";
import StripeContext from "components/context/StripeContext";
import UserProvider from "components/context/UserContext";
import { useRouter } from "components/hooks";
import SidebarLayout from "components/molecules/sidebar/SidebarLayout";
import Navbar from "components/pageComponents/Navbar/Navbar";
import { WalkthroughProvider } from "pages/UserProfile/walkthrough/WalkthroughContext";
import { Toaster } from "react-hot-toast";
import { ROUTES } from "./constants";

const SignIn = lazy(() => import("pages/signin"));
const CreateAccountPage = lazy(() => import("pages/CreateUserAccount"));
// const MerchDropPage = lazy(() => import("pages/MerchDrop"));
const BrandPublicProfile = lazy(() => import("pages/BrandPublicProfile"));
const BrandSettings = lazy(() => import("pages/BrandSettings"));
const BrandOnBoardingPage = lazy(() => import("pages/BrandOnboarding"));
const CreateMagnetPage = lazy(() => import("pages/CreateMagnet"));
const MagnetPage = lazy(() => import("pages/MagnetPage"));
const BrandSubmissionSuccessForm = lazy(() => import("components/organisms/BrandSubmissionSuccessForm"));
const BrandDashboard = lazy(() => import("pages/BrandDashboard"));
const EditMagnetPage = lazy(() => import("pages/BrandSettings/EditMagnet"));
const ComingSoon = lazy(() => import("pages/ComingSoon"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
const TermsConditions = lazy(() => import("pages/TermsConditions"));
const BrandDiscover = lazy(() => import("pages/BrandDiscover/BrandDiscoverContainer"));
const NotFound = lazy(() => import("pages/NotFound"));
const UserProfilePage = lazy(() => import("pages/UserProfile"));
const ProfileSettings = lazy(() => import("pages/UserSettings"));
const UserPublicProfilePage = lazy(() => import("pages/UserPublicProfile"));
// const ClaimCode = lazy(() => import("pages/ClaimCode"));
const ShareRequest = lazy(() => import("pages/ShareRequest"));
const NotificationPage = lazy(() => import("pages/Notification"));
const PollPage = lazy(() => import("pages/Poll"));
const Walkthrough = lazy(() => import("pages/UserProfile/walkthrough/Walkthrough"));
const Analytics = lazy(() => import("pages/Analytics"));
const AirdropMemento = lazy(() => import("pages/AirdropMemento"));
const ClaimMagnet = lazy(() => import("pages/MagnetPage/ClaimMagnet"));
const TwitterAuthorizationStatus = lazy(() => import("pages/TwitterAuthorizationStatus"));
const GoogleSigin = lazy(() => import("pages/GoogleSignin"));
const DataProvider = lazy(() => import("pages/MagnetDetails/DataProvider"));
// Docs https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const queryClient = new QueryClient();

function App() {
  const router = useRouter();
  // TODO: this is the temporary solution, fix this
  useEffect(() => {
    if (window.location.pathname === "/google/signin/undefined") {
      router.go(ROUTES.BRANDS_DISCOVER);
    }
  }, [window.location.pathname]);

  return (
    <Sentry.ErrorBoundary
      fallback={
        <p className="grid h-[100vh] w-[100vw] place-content-center bg-black text-2xl text-white">
          Something Went Wrong!
        </p>
      }
      showDialog
    >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen />
        <Toaster position="top-right" />
        {/* <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          toastStyle={{ backgroundColor: "#060F1B" }}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        /> */}
        <LoaderProvider>
          <StripeContext>
            <Suspense fallback={<div>Loading...</div>}>
              <SentryRoutes>
                <Route path={ROUTES.TWITTER_AUTHORIZATION} element={<TwitterAuthorizationStatus />} />
                <Route
                  path="*"
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  element={<AppWithLayout />}
                />
              </SentryRoutes>
            </Suspense>
          </StripeContext>
        </LoaderProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
}

function AppWithLayout() {
  const [isSidebarCustomized, setIsSidebarCustomized] = useState(false);

  return (
    <UserProvider>
      <BrandProvider>
        <main className="">
          <Navbar isSidebarCustomized={isSidebarCustomized} />
          <WalkthroughProvider>
            <GlobalMementoModalProvider>
              <Walkthrough />
              <SidebarLayout isSidebarCustomized={isSidebarCustomized}>
                <Suspense fallback={<div>Loading...</div>}>
                  <SentryRoutes>
                    {/* Public Routes */}
                    <Route path={ROUTES.CLAIM_MAGNET} element={<ClaimMagnet />} />
                    <Route path={ROUTES.GOOGLE_SIGNIN} element={<GoogleSigin />} />
                    <Route path={ROUTES.SIGNIN} element={<SignIn />} />
                    <Route path={ROUTES.CREATE_USER_ACCOUNT} element={<CreateAccountPage />} />
                    <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
                    <Route path={ROUTES.COMING_SOON} element={<ComingSoon />} />
                    <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
                    <Route path={`${ROUTES.USER_PUBLIC_PROFILE}/:userId`} element={<UserPublicProfilePage />} />
                    <Route path={`${ROUTES.BRAND_PUBLIC_PROFILE}/:brandId`} element={<BrandPublicProfile />} />
                    <Route path={ROUTES.BRANDS_DISCOVER} element={<BrandDiscover />} />
                    <Route
                      path={ROUTES.MAGNET_PAGE}
                      element={<MagnetPage setIsSidebarCustomized={setIsSidebarCustomized} />}
                    />
                    <Route path={`${ROUTES.TERMS_AND_CONDITIONS}`} element={<TermsConditions />} />
                    <Route path={ROUTES.BRANDS_SIGNUP_SUCCESS} element={<BrandSubmissionSuccessForm />} />

                    <Route element={<UserAccountRoute />}>
                      {/* User Specific Routes. */}
                      <Route element={<AuthenticatedRoute />}>
                        {/* This route corresponds to base path '/' */}
                        <Route path={ROUTES.PROFILE_SETTING} element={<ProfileSettings />} />
                        <Route path={ROUTES.USER_PROFILE_PAGE} element={<UserProfilePage />} />
                        <Route path={ROUTES.BRAND_ONBOARDING} element={<BrandOnBoardingPage />} />
                        <Route path={ROUTES.NOTIFICATION} element={<NotificationPage />} />
                        <Route element={<BrandRoute />}>
                          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
                          <Route path="*" element={<BrandWithContext />} />
                        </Route>
                      </Route>
                    </Route>

                    <Route path="*" element={<NotFound />} />
                  </SentryRoutes>
                </Suspense>
              </SidebarLayout>
            </GlobalMementoModalProvider>
          </WalkthroughProvider>
        </main>
      </BrandProvider>
    </UserProvider>
  );
}

function BrandWithContext() {
  return (
    <DataProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <SentryRoutes>
          <Route path={`${ROUTES.BRAND_ANALYTICS}`} element={<Analytics />} />
          <Route path={ROUTES.BRAND_DASHBOARD} element={<BrandDashboard />} />
          <Route path={ROUTES.BRAND_SETTINGS_ROUTE} element={<BrandSettings />} />
          <Route path={ROUTES.CREATE_CONTROLLED_MAGNET_ROUTE} element={<CreateMagnetPage />} />
          <Route path={ROUTES.CREATE_PUBLIC_MAGNET_ROUTE} element={<CreateMagnetPage />} />
          <Route path={ROUTES.CREATE_SUBSCRIPTION_MAGNET_ROUTE} element={<CreateMagnetPage />} />

          <Route path={`${ROUTES.EDIT_MAGNET}/:magnetId`} element={<EditMagnetPage />} />
          <Route path={ROUTES.AIRDROP_MEMENTO} element={<AirdropMemento />} />
          <Route path={ROUTES.EDIT_AIRDROP_MEMENTO} element={<AirdropMemento isEditForm />} />
          <Route path={ROUTES.CREATE_POLL} element={<PollPage />} />
          <Route path={ROUTES.EDIT_POLL} element={<PollPage isEditForm />} />
          {/* <Route path={ROUTES.MERCH_DROP} element={<MerchDropPage />} />
    <Route path={ROUTES.EDIT_MERCH_DROP} element={<MerchDropPage isEditForm />} /> */}
          {/* <Route path={ROUTES.CREATE_CLAIM_CODE} element={<ClaimCode />} />
    <Route path={ROUTES.EDIT_CLAIM_CODE} element={<ClaimCode isEditForm />} /> */}
          <Route path={ROUTES.CREATE_SHARE_REQUEST} element={<ShareRequest />} />
          <Route path={ROUTES.EDIT_SHARE_REQUEST} element={<ShareRequest isEditForm />} />
        </SentryRoutes>
      </Suspense>
    </DataProvider>
  );
}

export default App;

import { Fragment, useContext } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { SettingsIcons, SignoutIcon } from "components/atoms/Icon";
import DapperIcon from "assets/Images/dapper.png";
import { useRouter } from "components/hooks";
import { isDeviceMobile } from "utils/helpers";
import OverFlowManager from "components/atoms/OverflowManager";
import { LocalLibraryOutlined } from "@mui/icons-material";
import toast from "react-hot-toast";
import { restartMagnetiqProgram } from "apis/user";
import { ROUTES } from "../../../constants";
import { UserContext } from "../../context/UserContext";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function UserProfileDropdown() {
  const { handleUserLogout, handleRedirectToDapper, userDetails, setupUserAccountAndAirDropMagnet } =
    useContext(UserContext);
  const router = useRouter();

  const handleNavigateToUserProfilePage = () => {
    router.go(ROUTES.PROFILE_SETTING);
  };

  const handleRestartJoyride = () => {
    restartMagnetiqProgram(
      () => {
        window.location.replace(`${ROUTES.USER_PROFILE_PAGE}?onboarding-step=0`);
      },
      () => {
        toast.error("Something Went Wrong! Please try again after some time.");
      },
    );
  };

  const isMobile = isDeviceMobile();

  // Mobile View
  if (isMobile) {
    return (
      <Popover>
        {({ open, close }) => (
          <>
            <OverFlowManager open={open} />
            <Popover.Button className="relative z-30 flex items-center rounded-full">
              <span className="sr-only">User Profile</span>
              <UserCircleIcon className="h-6 w-6 cursor-pointer text-white hover:opacity-80" />
            </Popover.Button>
            <Popover.Panel
              style={
                {
                  // backgroundImage: "linear-gradient(179.99deg, #000000 87.29%, #FED402 124.69%);",
                }
              }
              className="absolute inset-0 top-[66px] z-50 h-[calc(100vh-66px)] w-screen bg-black "
            >
              <div className="flex h-full flex-col">
                <div className="grow">
                  <div className="mx-5 space-y-2">
                    <img
                      src={userDetails?.profile_image_url || undefined}
                      alt="profile_picture"
                      className="h-24 w-24 rounded-full"
                    />
                    <p className="text-3xl font-medium text-white">{userDetails?.full_name}</p>
                  </div>
                  <hr className="my-3 text-gray-200" />

                  {/* Dapper Wallet Button */}
                  <button
                    onClick={() => {
                      if (!userDetails?.is_dapper_setup) setupUserAccountAndAirDropMagnet();
                      else
                        handleRedirectToDapper(
                          userDetails.wallet,
                          userDetails.wallet_type ? userDetails.wallet_type : "",
                        );
                      close();
                    }}
                    type="button"
                    className={classNames("text-white hover:bg-opacity-80", "block w-full px-5 py-3 text-left text-lg")}
                  >
                    <span className="flex items-center space-x-2">
                      <img src={DapperIcon} alt="dapper_logo" className="h-6 w-6" />
                      <span>{!userDetails?.is_dapper_setup ? "LINK WALLET" : "WALLET"}</span>
                    </span>
                  </button>
                  {/* Profile Settings Button */}
                  <button
                    onClick={() => {
                      handleNavigateToUserProfilePage();
                      close();
                    }}
                    type="button"
                    className={classNames("block w-full bg-opacity-80 px-5 py-3 text-left text-lg text-white")}
                  >
                    <span className="flex items-center space-x-2">
                      <SettingsIcons />
                      <span>PROFILE SETTINGS</span>
                    </span>
                  </button>
                </div>

                {/* Restart JoyRide button */}
                <button
                  onClick={handleRestartJoyride}
                  type="button"
                  className="al block w-full flex-none bg-opacity-80 px-5 py-3 text-left text-lg text-white"
                >
                  <span className="flex items-center space-x-2">
                    <LocalLibraryOutlined className="scale-110 text-secondary" />
                    <span>WALKTHROUGH</span>
                  </span>
                </button>
                {/* Sign out button */}
                <button
                  onClick={handleUserLogout}
                  type="button"
                  className={classNames(
                    "bg-opacity-80 text-white",
                    "al block w-full flex-none px-5 py-3 text-left text-lg",
                  )}
                >
                  <span className="flex items-center space-x-2">
                    <SignoutIcon />
                    <span>SIGN OUT</span>
                  </span>
                </button>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    );
  }

  return (
    <Menu as="div" className="relative z-40 inline-block text-left">
      <div>
        <Menu.Button className="relative z-40 flex items-center rounded-full">
          <span className="sr-only">User Profile</span>
          <UserCircleIcon className="h-8 w-8 cursor-pointer text-white hover:opacity-80" />
        </Menu.Button>
      </div>

      {/* Desktop */}
      {!isMobile ? (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute right-0 z-10 mt-2 w-64 origin-top-right
         rounded-ll bg-primary shadow-sm shadow-white"
          >
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      if (!userDetails?.is_dapper_setup) setupUserAccountAndAirDropMagnet();
                      else
                        handleRedirectToDapper(
                          userDetails.wallet,
                          userDetails.wallet_type ? userDetails.wallet_type : "",
                        );
                    }}
                    type="button"
                    className={classNames(
                      active ? "bg-opacity-80 text-white" : "text-white",
                      "block w-full px-5 py-3 text-left text-sm",
                    )}
                  >
                    <span className="flex items-center space-x-3">
                      <img src={DapperIcon} alt="dapper_logo" className="h-6 w-6" />
                      <span>{!userDetails?.is_dapper_setup ? "LINK WALLET" : "WALLET"}</span>
                    </span>
                  </button>
                )}
              </Menu.Item>
              <hr className="border-white" />
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleNavigateToUserProfilePage}
                    type="button"
                    className={classNames(
                      active ? "bg-opacity-80 text-white" : "text-white",
                      "block w-full px-5 py-3 text-left text-sm",
                    )}
                  >
                    <span className="flex items-center space-x-2">
                      <SettingsIcons />
                      <span>PROFILE SETTINGS</span>
                    </span>
                  </button>
                )}
              </Menu.Item>
              <hr className="border-white" />
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleRestartJoyride}
                    type="button"
                    className={classNames(
                      active ? "bg-opacity-80 text-white" : "text-white",
                      "block w-full px-5 py-3 text-left text-sm",
                    )}
                  >
                    <span className="flex items-center space-x-3">
                      <LocalLibraryOutlined className="scale-110 text-secondary" />
                      <span>WALKTHROUGH</span>
                    </span>
                  </button>
                )}
              </Menu.Item>
              <hr className="border-white" />
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleUserLogout}
                    type="button"
                    className={classNames(
                      active ? "bg-opacity-80 text-white" : "text-white",
                      "block w-full px-5 py-3 text-left text-sm",
                    )}
                  >
                    <span className="flex items-center space-x-2">
                      <SignoutIcon />
                      <span>SIGN OUT</span>
                    </span>
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      ) : (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover>
            {() => <Popover.Panel className="absolute inset-0 z-10 mt-2 h-screen w-screen   bg-red-300" />}
          </Popover>
        </Transition>
      )}
    </Menu>
  );
}

import clsx from "clsx";
import { SyntheticEvent, useState } from "react";
import { generateVideoThumbnail } from "utils/blurhashhelpers";
import { isMediaOfTypeVideo } from "utils/helpers";

/* eslint-disable jsx-a11y/media-has-caption */
type Props = {
  src?: string;
  videoPlayerId: string;
  setGeneratedThumbnail?: (src: File) => void;
  handleVideoClick?: any;
  videoContainerAdditionalClasses?: string;
  thumbnailUrl?: string;
  autoPlay?: boolean;
  allowPlayback?: boolean;
  disablePlayButton?: boolean;
};

export default function VideoPreviewer({
  src,
  setGeneratedThumbnail,
  videoPlayerId,
  handleVideoClick,
  videoContainerAdditionalClasses,
  thumbnailUrl,
  autoPlay = true,
  allowPlayback = true,
  disablePlayButton = false,
}: Props) {
  const [isThumbnailVisible, setIsThumbnailVisible] = useState(true);

  const handleGenerateVideoThumbnail = async (e: SyntheticEvent<HTMLVideoElement, Event>) => {
    if (setGeneratedThumbnail) {
      const file = await generateVideoThumbnail(e.currentTarget);
      if (file) {
        setGeneratedThumbnail(file);
      }
    }
  };

  return (
    <div className={clsx("relative flex h-full w-full flex-col items-center", videoContainerAdditionalClasses)}>
      {thumbnailUrl && (
        <img
          src={thumbnailUrl}
          alt="memento thumbnail"
          className="absolute mx-auto block h-full overflow-hidden object-contain"
        />
      )}
      <video
        onClick={(e) => {
          e.preventDefault();
          if (allowPlayback && !disablePlayButton) {
            handleVideoClick?.();
          }
        }}
        onLoadedData={() => {
          if (isThumbnailVisible && isMediaOfTypeVideo(src as string)) {
            setIsThumbnailVisible(false);
          }
        }}
        autoPlay={autoPlay && !disablePlayButton}
        id={videoPlayerId}
        onLoadedDataCapture={handleGenerateVideoThumbnail}
        className={clsx(
          "absolute h-full w-full",
          isThumbnailVisible ? "bg-transparent" : "bg-primary",
          disablePlayButton && "pointer-events-none",
        )}
        controls={allowPlayback}
        controlsList="nodownload"
        onContextMenu={(e) => e.preventDefault()}
        src={src}
      />
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
import * as fcl from "@onflow/fcl";
import {
  DAPPER_ADDRESS,
  FLOW_NETWORK,
  GET_NOUNCE,
  REACT_APP_DISCOVERY_AUTHN_ENDPOINT,
  REACT_APP_FLOW_ACCESS_NODE,
  REACT_APP_WALLET_DISCOVERY,
} from "./constants";
import { get } from "./utils/request";

export default async function initFCL(useResolver: boolean = true) {
  const resolver = async () => {
    const response = await get(GET_NOUNCE);
    if (!response) {
      alert("Failed to get nonuce from BE.");
      return null;
    }
    return response;
  };

  fcl
    .config()
    .put("flow.network", FLOW_NETWORK)
    .put("fcl.accountProof.resolver", useResolver ? JSON.stringify(resolver) : "")
    .put("walletconnect.projectId", "c17ddec84010c72c473c341e68a98a81")
    .put("accessNode.api", REACT_APP_FLOW_ACCESS_NODE)
    .put("discovery.wallet", REACT_APP_WALLET_DISCOVERY)
    .put("discovery.authn.endpoint", REACT_APP_DISCOVERY_AUTHN_ENDPOINT)
    .put("discovery.authn.include", JSON.stringify([DAPPER_ADDRESS]))
    .put("app.detail.title", "Magnetiq")
    .put(
      "app.detail.icon",
      "https://magnetiq-static.s3.amazonaws.com/media/public/Achievement_10Magnets_Owned_kCSc0sz.jpg",
    )
    .put("service.OpenID.scopes", "email preferred_username");
}

import autoAnimate from "@formkit/auto-animate";
// import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useEffect, useRef } from "react";

type Props = {
  children: React.ReactNode;
};

export default function ListAnimator({ children }: Props) {
  // const [parent] = useAutoAnimate({ duration: 250, disrespectUserMotionPreference: true });

  const parent = useRef(null);

  useEffect(() => {
    if (parent.current) {
      autoAnimate(parent.current);
    }
  }, [parent]);

  return (
    // @ts-ignore
    <div className="contents" ref={parent}>
      {children}
    </div>
  );
}
